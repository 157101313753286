<template>
  <section v-if="errored">
    <p>
      Nous sommes désolés, nous ne sommes pas en mesure de récupérer ces
      informations pour le moment. Veuillez réessayer ultérieurement.
    </p>
  </section>

  <section v-else>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <section v-if="!loading" class="text-center ma-5">
      <h3>{{ this.donnees.attributes.Nom }} - {{ this.donnees.attributes.Identifiant }}</h3>
      <br />
      <v-container style="padding:0px">
        <v-row class="dossier">
          <div class="d-flex justify-center fullWidth flex-wrap flex-md-nowrap"
            ><v-img
              style="flex:auto;"
              :src="getImgUrl(this.donnees.attributes.PhotoAvant)"
              v-if="this.donnees.attributes.PhotoAvant.data != null"
              :contain="true"
              :lazy-src="getLazyUrl(this.donnees.attributes.PhotoAvant)"
            ></v-img
            ><v-img
              style="flex:auto;"
              :src="getImgUrl(this.donnees.attributes.PhotoArriere)"
              v-if="this.donnees.attributes.PhotoArriere.data != null"
              :contain="true"
              :lazy-src="getLazyUrl(this.donnees.attributes.PhotoArriere)"
            ></v-img
          ></div>
        </v-row>
      </v-container>
      <br />
      <section v-if="this.donnees.attributes.visionneuses.data != null &&this.donnees.attributes.visionneuses.data.length > 0">
        <div
          v-for="visionneuse in this.donnees.attributes.visionneuses.data"
          v-bind:key="visionneuse.id"
        >
          <Visionneuse :visio="visionneuse.attributes" :token="token" />
          <br />
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import Visionneuse from "./Visionneuse.vue";
import { getImgUrl, getLazyUrl } from "../scripts";

export default {
  name: "Chassis",
  props: ["parent", "identifiant", "token"],

  components: {
    Visionneuse,
  },

  data: () => ({
    donnees: null,
    errored: null,
    loading: true,
  }),

  mounted() {
    this.id = this.identifiant;
    axios
      .get(
        process.env.VUE_APP_STRAPI_URL +
          "/api/chassis-voitures?populate=PhotoAvant,PhotoArriere,visionneuses&populate[1]=visionneuses.Photos&filters[slug][$eqi]=" +
          this.id,
        {
          headers: {
            Authorization: this.token,
          },
        }
      )
      .then((response) => {
        this.donnees = response.data.data[0];
      })
      .catch((error) => {
        console.error(error);
        console.error(this.id);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },

  methods: {
    getImgUrl,
    getLazyUrl,
    generateUrl(enfant) {
      return this.parent + "/f" + enfant.replaceAll("/", "-");
    },
    clickRetour() {
      history.back(1);
    },
  },
};
</script>

<style>
.image {
  max-width: 1000px;
  margin: auto;
}
.invisible {
  visibility: hidden;
}
.dossier {
  max-width: 1000px;
  width: 100%;
  margin: auto;
}
.v-carousel__controls__item {
  color: black !important;
}
.v-carousel__controls {
  background: none;
}
ul.cards-contrainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, minmax(255px, max-content));
}
.cards {
  /* padding: 5px; */
  width: 255px;
  height: 192px;
  margin: 10px;
  float: left;
  list-style: none;
}
.cards-img {
  height: 110px;
}
.v-card__title {
  font-size: 16px !important;
  font-weight: normal !important;
  padding: 16px 8px;
  text-align: center;
  line-height: 20px !important;
  word-break: normal !important;
}
.prez {
  max-height: 500px;
  max-width: 50%;
  padding: 0px;
}
.fullWidth {
  width: 100%;
}
</style>