<template>
  <v-app>
    <v-app-bar app color="grey lighten-5" light style="height: 96px">
      <v-col>
        <v-row>
          <v-spacer />
          <h1 class="text-h6 text-md-h4">
            <a
              href="https://pierrecourtin.fr"
              style="
                color: black;
                text-decoration: none;
                font-family: Optima;
                font-weight: normal;
              "
            >
              LES VIES D'ASTON MARTIN
            </a>
          </h1>
          <v-spacer />
        </v-row>
        <v-row class="flex-nowrap">
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Recherche"
            @input="inputed"
            @keyup.enter.native="searchFunc"
            :append-outer-icon="search ? 'mdi-send' : ''"
            @click:append-outer="searchFunc"
            clearable
            @click:clear="clearFunc"
          />
          <v-spacer />
          <v-btn
            v-if="token != null"
            class="orange darken-1 white--text d-flex d-md-none"
            fab
            small
            @click="clickDisconnect()"
            ><v-icon small> mdi-logout </v-icon></v-btn
          >
          <v-spacer class="d-flex d-md-none" />
        </v-row>
      </v-col>
      <v-btn
        v-if="token != null"
        class="orange darken-1 white--text ma-5 d-none d-md-flex"
        style="position: absolute; right: 10px"
        @click="clickDisconnect()"
        >Se déconnecter</v-btn
      >
    </v-app-bar>

    <v-main style="padding: 96px 0px 0px" class="ma-5">
      <section
        v-if="this.isEmpty && token != null"
      >
        <v-btn
          v-if="params[params.length - 1] != ''"
          class="orange darken-1 white--text ma-5"
          @click="clickRetour()"
          >Retour</v-btn
        >
        <div v-if="token != null">
          <Accueil v-if="params[params.length - 1] === ''" :token="token" />
          <Categorie
            v-if="params[params.length - 1].startsWith('e') || params[params.length - 1].startsWith('c')"
            :parent="url"
            :token="token"
            :puce="params[params.length - 1].substring(1)"
          />
          <Subcategorie
            v-if="params[params.length - 1].startsWith('c')"
            :parent="url"
            :token="token"
            :puce="params[params.length - 1].substring(1)"
          />
          <Voiture
            v-if="params[params.length - 1].startsWith('v')"
            :parent="url"
            :token="token"
            :identifiant="params[params.length - 1].substring(1)"
          />
          <Chassis
            v-if="params[params.length - 1].startsWith('f')"
            :parent="url"
            :token="token"
            :identifiant="params[params.length - 1].substring(1)"
          />
        </div>
      </section>
      <section class="text-center" v-else-if="token != null">
        <p v-if="this.resultCards.length == 0">
          Appuyer sur <kbd>Entrer</kbd> ou sur
          <v-icon color="primary">mdi-send</v-icon> pour lancer la recherche
        </p>
        <v-container>
          <v-row>
            <v-col>
              <v-combobox
                class="selector"
                v-model="selectedEpoques"
                hide-selected
                outlined
                label="Période"
                :items="this.epoques"
                clearable
                @input="searchFilter"
              ></v-combobox>
            </v-col>
            <v-col>
              <v-combobox
                class="selector"
                v-model="selectedCarrosseries"
                hide-selected
                outlined
                label="Type de carrosserie"
                :items="this.carrosseries"
                clearable
                @input="searchFilter"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-container>
        <section v-if="!this.isEmpty">
          <Recherche
            :token="this.token"
            :resultCards="this.resultCards"
            :parent="url"
          />
        </section>
        <section class="text-center" v-else>
          Aucun résultat pour votre recherche
        </section>
      </section>
      <section class="text-center" v-else>
        <v-form v-model="validForm" class="d-inline-flex flex-column">
          <v-alert
            v-model="expired"
            outlined
            type="warning"
            prominent
            border="left"
            >Votre session a expiré.<br />Veuillez vous reconnecter</v-alert
          >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
            solo
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Mot de passe"
            required
            solo
            type="password"
            @keydown.enter.prevent="clickConnect()"
          ></v-text-field>
          <VueRecaptcha
            :sitekey="siteKey"
            :load-recaptcha-script="true"
            @verify="handleSuccess"
            @error="handleError"
          ></VueRecaptcha>
          <br />
          <v-alert type="error" v-model="errored" transition="scale-transition">
            {{ error_msg }}
          </v-alert>
          <v-btn
            class="orange darken-1 white--text ma-5 px-16 py-7"
            @click="clickConnect()"
            >Se connecter</v-btn
          >
        </v-form>
      </section>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import Fuse from "fuse.js";
import { VueRecaptcha } from "vue-recaptcha";
import Recherche from "./components/Recherche.vue";
import Accueil from "./components/Accueil.vue";
import Categorie from "./components/Categorie.vue";
import Subcategorie from "./components/Subcategorie.vue";
import Voiture from "./components/Voiture.vue";
import Chassis from "./components/Chassis.vue";
import {
  getImgUrl,
  getLazyUrl,
  setCookie,
  getCookie,
  eraseCookie,
} from "./scripts";

export default {
  name: "App",

  components: {
    Recherche,
    Accueil,
    Categorie,
    Subcategorie,
    Voiture,
    Chassis,
    VueRecaptcha,
  },

  data: () => ({
    url: window.location.pathname,
    params: window.location.pathname.split("/").slice(1),
    search: "",
    graphql: [],
    keys: {
      categories: ["Nom", "Identifiant"],
      voitures: ["Nom", "Identifiant", "Adresse"],
      chassisVoitures: ["Nom", "Identifiant", "Adresse"],
    },
    resultCards: [],
    resultFuse: [],
    isEmpty: true,
    epoques: [],
    carrosseries: [
      "0 - Prototype / Présérie",
      "1 - Coupé / Saloon",
      "2 - DHC / Convertible / Volente / Cabriolet",
      "3 - FHC / Hardtop",
      "4 - Carrossiers",
      "5 - 4 portes",
      "6 - Shooting Brake",
      "7 - Silhouette",
      "8 - Sport Proto",
      "9 - Monoplace",
    ],
    selectedEpoques: null,
    selectedCarrosseries: null,
    token: null,
    email: "",
    emailRules: [
      (v) => !!v || "L'e-mail est requis",
      (v) =>
        //eslint-disable-next-line
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) ||
        "L'e-mail n'est pas valide",
    ],
    password: "",
    passwordRules: [(v) => !!v || "Le mot de passe ne doit pas être vide"],
    validForm: false,
    errored: false,
    error_msg: "Email ou mot de passe incorrect :(",
    expired: false,
    siteKey: "6LcAi1EkAAAAAPF4rekICGjqkdxykAsy1lsQ1oaB",
    captcha: false,
  }),

  mounted() {
    this.token = this.getCookie("auth");
    if (this.token != null) {
      let tokenExpiration =
        JSON.parse(
          Buffer.from(
            this.token.substring("Bearer ".length).split(".")[1],
            "base64"
          ).toString()
        ).exp * 1000;
      if (tokenExpiration <= Date.now()) {
        this.expired = true;
        this.clickDisconnect();
        return;
      }
      this.start();
    }
  },

  methods: {
    setCookie,
    getCookie,
    eraseCookie,
    start() {
      axios({
        url: process.env.VUE_APP_STRAPI_URL + "/graphql",
        method: "post",
        headers: {
          Authorization: this.token,
        },
        data: {
          query: `query {
  voitures {
    data {
      id
      attributes {
        Nom
        Identifiant
        Adresse
      }
    }
  }
  categories {
    data {
      id
      attributes {
        Nom
        Identifiant
      }
    }
  }
  chassisVoitures {
    data {
      id
      attributes {
        Nom
        Identifiant
        Adresse
      }
    }
  }
}
`,
        },
      }).then((result) => {
        this.graphql = result.data.data;
        if (this.params[0].startsWith("s") && this.params[3] == null) {
          this.search = decodeURIComponent(this.params[0].substring(1));
          this.selectedEpoques =
            decodeURIComponent(this.params[1].substring(1)) == ""
              ? null
              : decodeURIComponent(this.params[1].substring(1));
          this.selectedCarrosseries =
            decodeURIComponent(this.params[2].substring(1)) == ""
              ? null
              : decodeURIComponent(this.params[2].substring(1));
          this.inputed();
          this.searchFunc();
        }
      });
      axios
        .get(process.env.VUE_APP_STRAPI_URL + "/api/epoques", {
          headers: {
            Authorization: this.token,
          },
        })
        .then((response) => {
          let objEpoques = response.data.data;
          objEpoques.forEach((obj) => {
            const element = obj.attributes;
            const str = element.Puce + " - " + element.Titre;
            this.epoques.push(str);
          });
        });
    },
    clickConnect() {
      this.errored = false;
      this.expired = false;
      if (!this.captcha) {
        this.errored = true;
        this.error_msg = "reCAPTCHA invalide :(";
      } else
        axios({
          url: process.env.VUE_APP_STRAPI_URL + "/api/auth/local",
          method: "post",
          data: {
            identifier: this.email,
            password: this.password,
          },
        })
          .then((response) => {
            this.token = "Bearer " + response.data.jwt;
            this.setCookie("auth", this.token, 0.5);
            this.start();
            this.email = "";
            this.password = "";
          })
          .catch((error) => {
            console.log("An error occurred:", error.response);
            this.errored = true;
            this.error_msg = "Email ou mot de passe incorrect :(";
          });
    },
    clickDisconnect() {
      this.eraseCookie("auth");
      this.token = null;
      this.captcha = false;
    },
    inputed() {
      this.isEmpty = this.search.length == 0;
      this.resultFuse = [];
      this.resultCards = [];
    },
    clearFunc() {
      window.location.href = window.location.origin;
    },
    searchFunc() {
      if (
        !this.params[0].startsWith("s") ||
        (this.search != decodeURIComponent(this.params[0].substring(1)) &&
          this.search.length > 0)
      )
        window.location.href =
          window.location.origin +
          "/s" +
          encodeURIComponent(this.search) +
          "/e" +
          encodeURIComponent(this.selectedEpoques || "") +
          "/c" +
          encodeURIComponent(this.selectedCarrosseries || "");
      else if (this.search.length == 0) this.clearFunc();
      let jsonned = JSON.parse(JSON.stringify(this.graphql));
      let list = [];

      for (var type in jsonned) {
        list[type] = [];
        jsonned[type].data.forEach((obj) => {
          obj.attributes.id = obj.id;
          list[type].push(obj.attributes);
        });
      }
      this.resultFuse = [];
      for (const [key, value] of Object.entries(list)) {
        let options = { keys: this.keys[key], includeScore: true };
        const fuse = new Fuse(value, options);
        const result = fuse.search(this.search);
        result.forEach((element) => {
          element.item.source = key;
          this.resultFuse.push(element);
        });
      }
      this.resultFuse = this.resultFuse.filter((item) => item.score < 0.6);
      this.resultFuse.sort(function (a, b) {
        return a.score - b.score;
      });
      this.searchFilter();
    },
    searchFilter() {
      var filtered = [];
      if (
        (this.selectedEpoques || "") !=
        decodeURIComponent(this.params[1].substring(1))
      ) {
        window.location.href =
          window.location.origin +
          "/s" +
          encodeURIComponent(this.search) +
          "/e" +
          encodeURIComponent(this.selectedEpoques || "") +
          "/c" +
          encodeURIComponent(this.selectedCarrosseries || "");
      }
      if (
        (this.selectedCarrosseries || "") !=
        decodeURIComponent(this.params[2].substring(1))
      ) {
        window.location.href =
          window.location.origin +
          "/s" +
          encodeURIComponent(this.search) +
          "/e" +
          encodeURIComponent(this.selectedEpoques || "") +
          "/c" +
          encodeURIComponent(this.selectedCarrosseries || "");
      }
      if (this.selectedEpoques != null || this.selectedCarrosseries != null) {
        if (this.selectedEpoques != null) {
          var puce = this.selectedEpoques
            .substring(0, this.selectedEpoques.indexOf("-"))
            .trim();
        }
        if (this.selectedCarrosseries != null) {
          var id = this.selectedCarrosseries
            .substring(0, this.selectedCarrosseries.indexOf("-"))
            .trim();
        }
        var re = /\w+[. ](\d)\/?.*/;

        if (this.selectedEpoques != null && this.selectedCarrosseries != null) {
          this.resultFuse.forEach((element) => {
            if (element.item.Identifiant.startsWith(puce)) {
              var reres = re.exec(element.item.Identifiant);
              if (reres != null) {
                if (reres[1] == id) {
                  filtered.push(element);
                }
              }
            }
          });
        } else if (this.selectedEpoques != null) {
          this.resultFuse.forEach((element) => {
            if (element.item.Identifiant.startsWith(puce)) {
              filtered.push(element);
            }
          });
        } else if (this.selectedCarrosseries != null) {
          this.resultFuse.forEach((element) => {
            var reres = re.exec(element.item.Identifiant);
            console.log(reres);
            if (reres != null) {
              if (reres[1] == id) {
                filtered.push(element);
              }
            }
          });
        }
      } else {
        filtered = this.resultFuse;
      }
      this.getObjectFromResult(filtered);
    },
    getObjectFromResult(list) {
      this.resultCards = [];
      list.forEach((element) => {
        var link = process.env.VUE_APP_STRAPI_URL + "/api/";
        if (element.item.source == "chassisVoitures") {
          link += "chassis-voitures";
        } else {
          link += element.item.source;
        }
        link += "/";
        link += element.item.id;
        link += "?populate=*";
        axios
          .get(link, {
            headers: {
              Authorization: this.token,
            },
          })
          .then((response) => {
            this.resultCards.push(response.data.data);
          });
      });
      this.isEmpty = list.length == 0;
    },
    clickRetour() {
      window.location.pathname = this.url.slice(0, this.url.lastIndexOf("/"));
    },
    getImgUrl,
    getLazyUrl,
    generateUrl(enfant) {
      return "/c" + enfant.replaceAll("/", "-");
    },
    handleSuccess() {
      this.captcha = true;
    },
    handleError() {
      this.captcha = false;
      console.error("error reCAPTCHA");
    },
  },
};
</script>

<style>
.v-toolbar__content {
  height: 96px !important;
}
ul.cards-contrainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, minmax(255px, max-content));
}
.cards {
  /* padding: 5px; */
  width: 255px;
  height: 192px;
  margin: 10px;
  float: left;
  list-style: none;
}
.cards-img {
  height: 110px;
}
.v-card__title {
  font-size: 16px !important;
  font-weight: normal !important;
  padding: 16px 8px;
  text-align: center;
  line-height: 20px !important;
  word-break: normal !important;
}
.selector {
  width: 80%;
  margin: auto !important;
}
kbd {
  background-color: #eee !important;
  border-radius: 3px !important;
  border: 1px solid #b4b4b4 !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset !important;
  color: #333 !important;
  display: inline-block !important;
  font-size: 0.85em !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  padding: 2px 4px !important;
  white-space: nowrap !important;
}
</style>