<template>
    <section v-if="errored" class="text-center ma-10">
        <p>Nous sommes désolés, nous ne sommes pas en mesure de récupérer ces informations pour le moment. Veuillez réessayer ultérieurement.</p>
    </section>

    <section v-else>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <ul class="cards-contrainer">
            <li class="cards item" v-for="epoque in epoques" v-bind:key="epoque.id">
                <v-card v-if="epoque.attributes.Visible" :href="'e'+epoque.attributes.slug">
                    <v-img :src="getImgUrl(epoque.attributes.Image)" class="cards-img" :contain=true></v-img>
                    <v-card-title class="cards-title">{{epoque.attributes.Puce}} - {{epoque.attributes.Titre}}</v-card-title>
                </v-card>
            </li>
        </ul>
    </section>
</template>

<script>
import axios from 'axios';
import { getImgUrl } from "../scripts";

export default {
    name: 'Accueil',
    props: ["token"],

    data: () => ({
        epoques: null,
        errored: null,
        loading: true,
    }),

    mounted () {
        axios
        .get(process.env.VUE_APP_STRAPI_URL + '/api/epoques?populate=Image', {headers: {
          Authorization: this.token,
        }})
        .then(response => {
            this.epoques = response.data.data
        })
        .catch(error => {
            console.error(error)
            this.errored = true
        })
        .finally(() => this.loading = false)
    },
    methods: {
        getImgUrl,
    }
}
</script>

<style>
ul.cards-contrainer{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, minmax(255px, max-content));
}
.cards{
    padding: 5px;
    width: 255px;
    height: 192px;
    margin: 10px;
    float: left;
    list-style:none;
}
.cards-img{
    height: 110px;
}
.v-card__title{
    font-size:16px !important;
    font-weight: normal !important;
    padding: 16px 8px;
    text-align: center;
    line-height: 20px !important;
    word-break: normal !important;
}
</style>