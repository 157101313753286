<template>
  <section v-if="errored">
    <p>
      Nous sommes désolés, nous ne sommes pas en mesure de récupérer ces
      informations pour le moment. Veuillez réessayer ultérieurement.
    </p>
  </section>

  <section v-else>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <ul class="cards-contrainer">
      <li class="cards item" v-for="d in this.data" v-bind:key="d.attributes.Identifiant">
        <section v-if="d.attributes.origin == 'categorie'">
          <v-card v-if="d.attributes.Visible" :href="generateUrl(d.attributes.slug)">
            <v-img
              :src="getImgUrl(d.attributes.Image)"
              class="cards-img"
              :contain="true"
              :lazy-src="getLazyUrl(d.attributes.Image)"
            >
            </v-img>
            <v-card-title class="cards-title"
              >{{ d.attributes.Nom }} - {{ d.attributes.Identifiant }}</v-card-title
            >
          </v-card>
        </section>
        <section v-else>
          <v-hover v-slot="{ hover }">
            <v-card v-if="d.attributes.Visible" :href="generateUrlVoiture(d.attributes.slug)">
              <v-img
                :src="getImgUrl(d.attributes.PhotoAvant)"
                v-if="!hover || d.attributes.PhotoArriere.data == null"
                class="cards-img"
                :contain="true"
                :lazy-src="getLazyUrl(d.attributes.PhotoAvant)"
              >
              </v-img>
              <v-img
                :src="getImgUrl(d.attributes.PhotoArriere)"
                v-if="hover && d.attributes.PhotoArriere.data != null"
                class="cards-img"
                :contain="true"
                :lazy-src="getLazyUrl(d.attributes.PhotoArriere)"
              >
              </v-img>
              <v-card-title class="cards-title"
                >{{ d.attributes.Nom }} - {{ d.attributes.Identifiant }}</v-card-title
              >
            </v-card>
          </v-hover>
        </section>
      </li>
    </ul>
  </section>
</template>

<script>
import axios from "axios";
import { getImgUrl, getLazyUrl } from "../scripts";

export default {
  name: "Subcategorie",
  props: ["parent", "puce", "token"],

  data: () => ({
    enfants: null,
    voitures: null,
    errored: null,
    loading: true,
    data: null,
  }),

  mounted() {
    this.id = this.puce;
    axios
      .get(
        process.env.VUE_APP_STRAPI_URL +
          "/api/categories?populate=Image&filters[parent][slug][$eqi]=" +
          this.id,
        {
          headers: {
            Authorization: this.token,
          },
        }
      )
      .then((response) => {
        this.enfants = response.data.data;
      })
      .catch((error) => {
        console.error(error);
        this.errored = true;
      })
      .finally(() => {
        axios
          .get(
            process.env.VUE_APP_STRAPI_URL +
              "/api/voitures?populate=PhotoAvant,PhotoArriere,FicheHistorique&filters[categorie][slug][$eqi]=" +
              this.id,
            {
              headers: {
                Authorization: this.token,
              },
            }
          )
          .then((response) => {
            this.voitures = response.data.data;
            var voitureJSON = JSON.parse(JSON.stringify(this.voitures));
            if (voitureJSON != null) {
              for (let v of voitureJSON) {
                v.attributes.origin = "voiture";
              }
            }
            var categoriesJSON = JSON.parse(JSON.stringify(this.enfants));
            if (categoriesJSON != null) {
              for (let c of categoriesJSON) {
                c.attributes.origin = "categorie";
              }
              this.data = categoriesJSON.concat(voitureJSON);
            } else {
              this.data = voitureJSON;
            }
            this.data.sort((a, b) => (a.attributes.slug < b.attributes.slug ? -1 : 1));
            console.log(this.data)
          })
          .catch((error) => {
            console.error(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      });
  },
  methods: {
    getImgUrl,
    getLazyUrl,
    generateUrl(enfant) {
      return this.parent + "/c" + enfant.replace("/", "-");
    },
    generateUrlVoiture(enfant) {
      return this.parent + "/v" + enfant.replace("/", "-");
    },
  },
};
</script>

<style>
ul.cards-contrainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, minmax(255px, max-content));
}
.cards {
  width: 255px;
  height: 192px;
  margin: 10px;
  float: left;
  list-style: none;
}
.cards-img {
  height: 110px;
}
.v-card__title {
  font-size: 16px !important;
  font-weight: normal !important;
  padding: 16px 8px;
  text-align: center;
  line-height: 20px !important;
  word-break: normal !important;
}
</style>