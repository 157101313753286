<template>
  <section v-if="errored">
    <p>
      Nous sommes désolés, nous ne sommes pas en mesure de récupérer ces
      informations pour le moment. Veuillez réessayer ultérieurement.
    </p>
  </section>

  <section v-else>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <section v-if="!loading" class="text-center ma-md-5">
      <h3>
        {{ this.donnees.attributes.Nom }} -
        {{ this.donnees.attributes.Identifiant }}
      </h3>

      <section v-if="dossier">
        <v-container class="d-flex justify-space-around mb-10">
          <v-btn class="red darken-1 white--text" @click="clickBack()"
            >Retour</v-btn
          >
          <v-btn class="invisible" @click="clickBack()">Retour</v-btn>
        </v-container>
        <div class="dossier">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Notice Descriptive
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Il n'y a rien ici.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Catalogue </v-expansion-panel-header>
              <v-expansion-panel-content>
                Il n'y a rien ici.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Article(s) </v-expansion-panel-header>
              <v-expansion-panel-content>
                Il n'y a rien ici.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Historique </v-expansion-panel-header>
              <v-expansion-panel-content>
                <section
                  v-if="this.donnees.attributes.FicheHistorique.data != null"
                  class="dossier"
                >
                  <!-- TODO : PDFVuer -->
                  <!-- <pdf :src="this.pdfdata" v-for="i in 10" :key="i" :page="i">
                        <template slot="loading">loading....</template>
                    </pdf> -->
                  <pdf :src="this.pdfdata" :page="1">
                    <template slot="loading">Chargement du PDF....</template>
                  </pdf>
                  <pdf :src="this.pdfdata" :page="2" />
                  <pdf :src="this.pdfdata" :page="3" />
                  <pdf :src="this.pdfdata" :page="4" />
                  <pdf :src="this.pdfdata" :page="5" />
                  <pdf :src="this.pdfdata" :page="6" />
                  <v-btn
                    class="orange darken-1 white--text d-flex d-md-none"
                    :href="
                      this.getImgUrl(
                        this.donnees.attributes.FicheHistorique.data
                      )
                    "
                    >Voir la fiche</v-btn
                  >
                </section>
                <section v-else>Il n'y a rien ici.</section>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Photos </v-expansion-panel-header>
              <v-expansion-panel-content>
                <section
                  v-if="this.donnees.attributes.visionneuses.data.length > 0"
                  class="dossier"
                >
                  <div
                    v-for="visionneuse in this.donnees.attributes.visionneuses
                      .data"
                    v-bind:key="visionneuse.id"
                  >
                    <Visionneuse
                      :visio="visionneuse.attributes"
                      :token="token"
                    />
                    <br />
                  </div>
                </section>
                <section v-else>Il n'y a rien ici.</section>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </section>

      <section v-else-if="chassis">
        <v-container class="d-flex justify-space-around mb-10">
          <v-btn class="red darken-1 white--text" @click="clickBack()"
            >Retour</v-btn
          >
          <v-btn class="invisible" @click="clickBack()">Retour</v-btn>
        </v-container>
        <section v-if="this.children.length > 0">
          <ul class="cards-contrainer">
            <li
              class="cards item"
              v-for="chassi in this.children"
              v-bind:key="chassi.id"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  v-if="chassi.attributes.Visible"
                  :href="chassi.attributes.origin == 'chassis' ? generateUrlChassis(chassi.attributes.slug) : generateUrlSerie(chassi.attributes.slug)"
                >
                  <v-img
                    :src="getImgUrl(chassi.attributes.PhotoAvant)"
                    v-if="!hover || chassi.attributes.PhotoArriere.data == null"
                    class="cards-img"
                    :contain="true"
                    :lazy-src="getLazyUrl(chassi.attributes.PhotoAvant)"
                  >
                  </v-img>
                  <v-img
                    :src="getImgUrl(chassi.attributes.PhotoArriere)"
                    v-if="hover && chassi.attributes.PhotoArriere.data != null"
                    class="cards-img"
                    :contain="true"
                    :lazy-src="getLazyUrl(chassi.attributes.PhotoArriere)"
                  >
                  </v-img>
                  <v-card-title class="cards-title"
                    >{{ chassi.attributes.Nom }} -
                    {{ chassi.attributes.Identifiant }}</v-card-title
                  >
                </v-card>
              </v-hover>
            </li>
          </ul>
        </section>
        <section v-else>
          <p>Il n'y a rien ici.</p>
        </section>
      </section>

      <section v-else>
        <v-container class="d-flex justify-space-around mb-10">
          <!-- 
          Pour cacher les boutons si il n'y a rien
          <v-btn :class="[{ invisible: this.donnees.attributes.chassis.data.length <= 0 }, 'secondary']" @click="clickChassis()">Châssis</v-btn>
          <v-btn :class="[{ invisible: this.donnees.attributes.series.data.length <= 0 }, 'secondary']" @click="clickSerie()">Séries</v-btn> 
          -->
          <v-btn class="secondary" @click="clickChassis()">Châssis</v-btn>
          <v-btn class="secondary" @click="clickDossier()">Dossier</v-btn>
        </v-container>
        <section>
          <section
              class="dossier"
              v-if="this.donnees.attributes.FicheHistorique.data != null"
            >
              <!-- TODO : PDFVuer -->
              <!-- <pdf :src="this.pdfdata" v-for="i in 10" :key="i" :page="i">
                    <template slot="loading">loading....</template>
                </pdf> -->
              <pdf :src="this.pdfdata" :page="1">
                <template slot="loading">Chargement du PDF....</template>
              </pdf>
              <pdf :src="this.pdfdata" :page="2" />
              <pdf :src="this.pdfdata" :page="3" />
              <pdf :src="this.pdfdata" :page="4" />
              <pdf :src="this.pdfdata" :page="5" />
              <pdf :src="this.pdfdata" :page="6" />
            </section>
          <div class="d-flex justify-center fullWidth flex-wrap flex-md-nowrap">
            <v-img
              class="sideToSide"
              :src="getImgUrl(this.donnees.attributes.PhotoAvant)"
              v-if="this.donnees.attributes.PhotoAvant.data != null"
              :contain="true"
              :lazy-src="getLazyUrl(this.donnees.attributes.PhotoAvant)"
            ></v-img
            ><v-img
              class="sideToSide"
              :src="getImgUrl(this.donnees.attributes.PhotoArriere)"
              v-if="this.donnees.attributes.PhotoArriere.data != null"
              :contain="true"
              :lazy-src="getLazyUrl(this.donnees.attributes.PhotoArriere)"
            ></v-img>
          </div>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import Visionneuse from "./Visionneuse.vue";
import PdfvuerVue from "pdfvuer";
import { getImgUrl, getLazyUrl } from "../scripts";

export default {
  name: "Voiture",
  props: ["parent", "identifiant", "token"],

  components: {
    Visionneuse,
    pdf: PdfvuerVue,
  },

  data: () => ({
    donnees: null,
    children: null,
    errored: null,
    loading: true,
    dossier: false,
    chassis: false,
    pdfnum: undefined,
    pdfdata: null,
  }),

  async mounted() {
    this.id = this.identifiant;
    let response = await axios
      .get(
        process.env.VUE_APP_STRAPI_URL +
          "/api/voitures?populate[0]=PhotoAvant,PhotoArriere,visionneuses,chassis,series,FicheHistorique&populate[1]=visionneuses.Photos,chassis.PhotoAvant,chassis.PhotoArriere,series.PhotoAvant,series.PhotoArriere&filters[slug][$eqi]=" +
          this.id,
        {
          headers: {
            Authorization: this.token,
          },
        }
      )
      .catch((error) => {
        console.error({ error });
        this.errored = true;
      });
    this.donnees = response.data.data[0];
    if (this.donnees.attributes.FicheHistorique.data != null) {
      this.pdfdata = PdfvuerVue.createLoadingTask(
        this.getImgUrl(this.donnees.attributes.FicheHistorique.data)
      );
      this.pdfdata.then((pdf) => {
        this.pdfnum = pdf.numPages;
        this.loading = false;
      });
    } else this.loading = false;
    if (this.donnees.attributes.chassis != null)
      for (let c of this.donnees.attributes.chassis.data)
        c.attributes.origin = "chassis";
    if (this.donnees.attributes.series != null)
      for (let c of this.donnees.attributes.series.data)
        c.attributes.origin = "series";
    this.children = this.donnees.attributes.chassis.data.concat(this.donnees.attributes.series.data);
    this.children.sort((a, b) => (a.attributes.slug < b.attributes.slug ? -1 : 1));
  },

  methods: {
    clickChassis() {
      this.chassis = true;
    },
    clickDossier() {
      this.dossier = true;
    },
    clickBack() {
      this.dossier = false;
      this.chassis = false;
      this.serie = false;
    },
    getImgUrl,
    getLazyUrl,
    generateUrlChassis(enfant) {
      return this.parent + "/f" + enfant.replace("/", "-");
    },
    generateUrlSerie(enfant) {
      return this.parent + "/v" + enfant.replace("/", "-");
    },
    clickRetour() {
      history.back(1);
    },
  },
};
</script>

<style>
.image {
  max-width: 1000px;
  margin: auto;
}
.invisible {
  visibility: hidden;
}
.dossier {
  max-width: 1000px;
  width: 80%;
  margin: auto;
}

.sideToSide {
  flex: 1 1 0;
}

@media screen and (max-width: 960px) {
  .dossier {
    width: 100% !important;
  }

  .sideToSide {
    flex: 1 1 auto;
  }
}

.v-carousel__controls__item {
  color: black !important;
}
.v-carousel__controls {
  background: none;
}
ul.cards-contrainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, minmax(255px, max-content));
}
.cards {
  /* padding: 5px; */
  width: 255px;
  height: 192px;
  margin: 10px;
  float: left;
  list-style: none;
}
.cards-img {
  height: 110px;
}
.v-card__title {
  font-size: 16px !important;
  font-weight: normal !important;
  padding: 16px 8px;
  text-align: center;
  line-height: 20px !important;
  word-break: normal !important;
}
.fullWidth {
  width: 100%;
}
.invisible {
  visibility: hidden;
}
</style>