<template>
    <ul class="cards-contrainer">
            <li
              class="cards item"
              v-for="(object, index) in resultCards"
              v-bind:key="index"
            >
              <section v-if="object.attributes.Image != undefined">
                <v-card
                  v-if="object.attributes.Visible"
                  :href="generateUrl(object.attributes.slug)"
                >
                  <v-img
                    :src="getImgUrl(object.attributes.Image)"
                    class="cards-img"
                    :contain="true"
                    :lazy-src="getLazyUrl(object.attributes.Image)"
                  >
                  </v-img>
                  <v-card-title class="cards-title"
                    >{{ object.attributes.Nom }} - {{ object.attributes.Identifiant }}</v-card-title
                  >
                </v-card>
              </section>
              <section v-else-if="object.attributes.voitureParent != undefined">
                <v-hover v-slot="{ hover }">
                  <v-card
                    v-if="object.attributes.Visible"
                    :href="generateUrlChassis(object.attributes.slug)"
                  >
                    <v-img
                      :src="getImgUrl(object.attributes.PhotoAvant)"
                      v-if="!hover || object.attributes.PhotoArriere.data == null"
                      class="cards-img"
                      :contain="true"
                      :lazy-src="getLazyUrl(object.attributes.PhotoAvant)"
                    >
                    </v-img>
                    <v-img
                      :src="getImgUrl(object.attributes.PhotoArriere)"
                      v-if="hover && object.attributes.PhotoArriere.data != null"
                      class="cards-img"
                      :contain="true"
                      :lazy-src="getLazyUrl(object.attributes.PhotoArriere)"
                    >
                    </v-img>
                    <v-card-title class="cards-title"
                      >{{ object.attributes.Nom }} - {{ object.attributes.Identifiant }}</v-card-title
                    >
                  </v-card>
                </v-hover>
              </section>
              <section v-else>
                <v-hover v-slot="{ hover }">
                  <v-card
                    v-if="object.attributes.Visible"
                    :href="generateUrlVoiture(object.attributes.slug)"
                  >
                    <v-img
                      :src="getImgUrl(object.attributes.PhotoAvant)"
                      v-if="!hover || object.attributes.PhotoArriere.data == null"
                      class="cards-img"
                      :contain="true"
                      :lazy-src="getLazyUrl(object.attributes.PhotoAvant)"
                    >
                    </v-img>
                    <v-img
                      :src="getImgUrl(object.attributes.PhotoArriere)"
                      v-if="hover && object.attributes.PhotoArriere.data != null"
                      class="cards-img"
                      :contain="true"
                      :lazy-src="getLazyUrl(object.attributes.PhotoArriere)"
                    >
                    </v-img>
                    <v-card-title class="cards-title"
                      >{{ object.attributes.Nom }} - {{ object.attributes.Identifiant }}</v-card-title
                    >
                  </v-card>
                </v-hover>
              </section>
            </li>
          </ul>
</template>

<script>
import { getImgUrl, getLazyUrl } from "../scripts";
export default {
    name: "Recherche",
    props: ['resultCards','token', 'parent'],

    data: () => ({
    }),

    mounted() {
    },

    methods: {
        getImgUrl,
        getLazyUrl,
        generateUrl(enfant) {
            return this.parent + "/c" + enfant.replaceAll("/", "-");
        },
        generateUrlVoiture(enfant) {
            return this.parent + "/v" + enfant.replaceAll("/", "-");
        },
        generateUrlChassis(enfant) {
            return this.parent + "/f" + enfant.replaceAll("/", "-");
        },
    },

    components: {
    }
}
</script>

<style>

</style>