<template>
    <div v-if="this.visio.Visible">
        <p>{{this.visio.Titre}}</p>
        <v-carousel class="slideshow" :hide-delimiters="this.visio.Photos.data.length > this.maxDelimiters" :continuous="this.visio.Photos.data.length < this.maxDelimiters" height="auto">
            <v-carousel-item v-for="photo in this.visio.Photos.data" :key="photo.id" eager>
                <v-img eager :src="getImgUrl(photo)" class="image" :contain="true" :lazy-src="getLazyUrl(photo)"></v-img>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import { getImgUrl, getLazyUrl } from "../scripts";
export default {
    name: "Visionneuse",
    props: ['visio','token'],

    data: () => ({
        maxDelimiters: window.screen.width < 960 ? 5 : 10,
    }),

    mounted() {

    },

    methods: {
        getImgUrl,
        getLazyUrl,
    },

    components: {
    }
}
</script>

<style>
.slideshow {
    margin: auto;
    max-width: 1200px !important;
}
.image{
    max-height: 500px;
}
</style>